<!-- 十六强模板 -->
<template>
  <div class="container">
    <div class="firstLine">
      <div
        class="wrap"
        v-for="(item, index) in promChart.dataResult.slice(0, 16)"
        :key="index"
      >
        <div class="card" :class="item.gameSide === '1' ? 'host' : 'custom'">
          {{ item.gameSide === "1" ? "主场" : "客场" }}
        </div>
        <div class="edit">
          <template v-if="readOnly">
            <p>{{ item.teamName || "待登记" }}</p>
          </template>
          <template v-else>
            <el-input
              @blur="toCheck(item)"
              v-model="item.teamName"
              v-show="item.edit"
            >
            </el-input>
            <el-button type="text" @click="toEdit(item)" v-show="!item.edit">{{
              item.teamName || "待登记"
            }}</el-button>
          </template>
        </div>
      </div>
    </div>
    <div class="secondLine">
      <div
        class="second_wrap"
        v-for="(item, index) in promChart.dataResult.slice(16, 24)"
        :key="index"
      >
        <div class="card" :class="item.gameSide === '1' ? 'host' : 'custom'">
          {{ item.gameSide === "1" ? "主场" : "客场" }}
        </div>
        <div class="edit">
          <template v-if="readOnly">
            <p>{{ item.teamName || "待登记" }}</p>
          </template>
          <template v-else>
            <el-input
              @blur="toCheck(item)"
              v-model="item.teamName"
              v-show="item.edit"
            >
            </el-input>
            <el-button type="text" @click="toEdit(item)" v-show="!item.edit">{{
              item.teamName || "待登记"
            }}</el-button>
          </template>
        </div>
      </div>
    </div>
    <div class="thirdLine">
      <div
        class="third_wrap"
        v-for="(item, index) in promChart.dataResult.slice(24, 28)"
        :key="index"
      >
        <div class="card" :class="item.gameSide === '1' ? 'host' : 'custom'">
          {{ item.gameSide === "1" ? "主场" : "客场" }}
        </div>
        <div class="edit">
          <template v-if="readOnly">
            <p>{{ item.teamName || "待登记" }}</p>
          </template>
          <template v-else>
            <el-input
              @blur="toCheck(item)"
              v-model="item.teamName"
              v-show="item.edit"
            >
            </el-input>
            <el-button type="text" @click="toEdit(item)" v-show="!item.edit">{{
              item.teamName || "待登记"
            }}</el-button>
          </template>
        </div>
      </div>
    </div>
    <div class="forthLine">
      <div
        class="forth_wrap"
        v-for="(item, index) in promChart.dataResult.slice(28, 30)"
        :key="index"
      >
        <div class="card" :class="item.gameSide === '1' ? 'host' : 'custom'">
          {{ item.gameSide === "1" ? "主场" : "客场" }}
        </div>
        <div class="edit">
          <template v-if="readOnly">
            <p>{{ item.teamName || "待登记" }}</p>
          </template>
          <template v-else>
            <el-input
              @blur="toCheck(item)"
              v-model="item.teamName"
              v-show="item.edit"
            >
            </el-input>
            <el-button type="text" @click="toEdit(item)" v-show="!item.edit">{{
              item.teamName || "待登记"
            }}</el-button>
          </template>
        </div>
      </div>
    </div>
    <div class="lastLine">
      <div class="forth_wrap">
        <div class="card champion">冠军</div>
        <div class="edit">
          <template v-if="readOnly">
            <p>
              {{
                promChart.dataResult[promChart.dataResult.length - 1]
                  .teamName || "待登记"
              }}
            </p>
          </template>
          <template v-else>
            <el-input
              @blur="
                toCheck(promChart.dataResult[promChart.dataResult.length - 1])
              "
              v-model="
                promChart.dataResult[promChart.dataResult.length - 1].teamName
              "
              v-show="
                promChart.dataResult[promChart.dataResult.length - 1].edit
              "
              :disabled="readOnly"
            >
            </el-input>
            <el-button
              type="text"
              @click="
                toEdit(promChart.dataResult[promChart.dataResult.length - 1])
              "
              :disabled="readOnly"
              v-show="
                !promChart.dataResult[promChart.dataResult.length - 1].edit
              "
              >{{
                promChart.dataResult[promChart.dataResult.length - 1]
                  .teamName || "待登记"
              }}</el-button
            >
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sixteemTem",
  data() {
    return {
      promChart: {
        componentName: "sixteenTem",
        dataResult: [
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "2",
            edit: false,
          },
          {
            teamName: "",
            gameSide: "1",
            edit: false,
          },
        ],
      },
    };
  },
  props: {
    propPromChart: {
      type: String,
      default: "",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const propProm = JSON.parse(this.propPromChart);
    if (
      !this.propPromChart ||
      this.promChart.componentName !== propProm.componentName
    ) {
      return;
    }
    this.$set(this,'promChart',propProm);
  },
  methods: {
    toEdit(item) {
      item.edit = true;
    },
    toCheck(item) {
      item.edit = false;
    },
  },
};
</script>

<style scoped lang="less">
.container {
  padding: 12px;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 20px;
}
.firstLine,
.secondLine,
.thirdLine,
.forthLine,
.lastLine {
  width: 150px;
}
.thirdLine {
  margin-top: 105px;
}
.forthLine {
  margin-top: 245px;
}
.secondLine {
  margin-top: 35px;
}
.lastLine {
  margin-top: 510px;
}
.host {
  background-color: rgb(235, 112, 74);
}
.custom {
  background-color: rgb(84, 149, 218);
}
.card.champion {
  background-color: gold;
}
.card,
.edit {
  height: 30px;
  text-align: center;
  line-height: 30px;
  width: 100px;
}
.edit {
  background-color: white;
  margin-bottom: 5px;
}
.wrap {
  margin-bottom: 10px;
}
.second_wrap {
  margin-bottom: 80px;
}
.third_wrap {
  margin-bottom: 220px;
}
.forth_wrap {
  margin-bottom: 490px;
}
/deep/.el-input__inner{
    height:29px;
}
</style>
