<template>
  <div>
    <el-button
      style="margin-right: 24px"
      type="primary"
      plain
      @click="searchDialog = true"
      size="small"
      >选择队伍</el-button
    >
    <el-tag
      v-if="selectData.length !== 0"
      type="warning"
      style="margin-right: 12px"
      >{{ selectData[0].name || "" }}</el-tag
    >
    <el-button
      v-loading="loading"
      icon="el-icon-plus"
      size="small"
      type="primary"
      style="float: right; width: 100px"
      @click="submit"
      >发布</el-button
    >
    <el-dialog :visible.sync="searchDialog" title="选择队伍">
      <el-row style="margin-bottom: 12px" :gutter="50">
        <el-col :span="8">
          <el-input
            size="small"
            v-model="team_name"
            placeholder="请输入战队名字"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="3">
          <el-button size="small" type="primary" @click="search"
            >搜索</el-button
          >
        </el-col>
      </el-row>
      <template v-if="teamIdList.length === 0">
        <el-empty description="未搜索到队伍"></el-empty>
      </template>
      <template v-else>
        <el-table
          border
          height="300"
          ref="multipleTable"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :data="teamIdList"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="formId" prop="id" width="300">
          </el-table-column>
          <el-table-column label="战队名" prop="name" min-width="300">
          </el-table-column>
        </el-table>
      </template>
      <div slot="footer" style="text-align: center">
        <el-button size="small" type="primary" @click="finishChoose"
          >确 定</el-button
        >
        <el-button size="small" @click="searchDialog = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 发布 -->
    <el-select
      size="small"
      v-model="eventname"
      style="margin-bottom: 10px; margin-right: 30px"
      placeholder="请选择赛季"
    >
      <el-option
        v-for="item in options"
        :key="item.name"
        :label="item.name"
        :value="item.name"
      >
      </el-option>
    </el-select>
    <el-input
      :rows="4"
      v-model="chaMsg"
      type="textarea"
      maxlength="200"
      show-word-limit
      placeholder="请输入冠军简介"
    ></el-input>
    <!-- 展示 -->
    <el-scrollbar
      :native="false"
      wrapStyle=""
      wrapClass=""
      viewClass=""
      viewStyle=""
      :noresize="false"
      tag="section"
      style="height: calc(70vh - 80px)"
    >
      <baseTable :data="cham" :column="tableProps">
        <template #projectHeader="{ data }">
          <div>
            <svg-icon iconClass="choice" width="16px" height="12px"></svg-icon>
            <span class="golden">{{ data.events.name }}</span>
            <span class="fontWeight">{{
              new Date(data.events.opentime) | parseTime("{y}-{m}-{d}")
            }}</span>
            <span>
              冠军战队:
              <span class="fontWeight">{{ data.form.team_name }}</span>
            </span>
          </div>
          <div>
            <span
              ><i
                class="el-icon-edit"
                style="
                  color: rgb(91, 182, 242);
                  font-size: 16px;
                  cursor: pointer;
                "
                @click="edit(data)"
              ></i
            ></span>
            <i
              class="el-icon-delete"
              style="color: red; font-size: 16px; cursor: pointer"
              @click="handleDelete(data)"
            ></i>
          </div>
        </template>
        <template #teamMates="{ data }">
          <p v-for="item in data.form.role" :key="item.role_name">
            {{ item.role_lin }}-{{ item.role_name }}
          </p>
        </template>
        <template #msg="{ data }">
          <div style="width: 100%">{{ data.msg }}</div>
        </template>
        <template #tel="{ data }">
          <span>{{ data.form.team_tel }}</span>
        </template>
        <template #like="{ data }">
          <span>{{ data.form.piaoshu }}</span>
        </template>
      </baseTable>
    </el-scrollbar>
    <el-dialog
      title="修改冠军"
      :visible.sync="editVisible"
      width="60%"
      @close="closeEditDialog"
    >
      <el-form
        ref="updateForm"
        :model="editForm"
        label-position="right"
        label-width="120px"
        :rules="rules"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="战队FORMID" prop="formId">
              <el-input
                size="small"
                v-model="editForm.formId"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="赛季" prop="eventname">
              <el-select
                size="small"
                v-model="editForm.eventname"
                style="margin-bottom: 10px; margin-right: 30px"
                placeholder="请选择赛季"
              >
                <el-option
                  v-for="item in options"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="战队简介" prop="msg">
          <el-input
            type="textarea"
            :rows="4"
            v-model="editForm.msg"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeEditDialog" size="mini">取 消</el-button>
        <el-button size="mini" type="primary" @click="updateCham('updateForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchId,
  saveCham,
  getAllCham,
  deleteCham,
  updateChampion,
} from "@/api/cham/index.js";
import { getAllEvents } from "@/api/gameSeason/index";
import baseTable from "@/components/baseTable.vue";

export default {
  name: "Blok",
  components: {
    baseTable,
  },
  data() {
    return {
      loading: false,
      searchDialog: false,
      id: null,
      team_name: "",
      teamIdList: [],
      iconToggle: "el-icon-download",
      showMsg: "打开",
      options: [],
      eventname: "",
      chaMsg: "", //冠军简介
      cham: [],
      tableProps: [
        {
          type: "index",
          label: "序号",
          props: "index",
          style: {
            textAlign: "center",
            minWidth: "60px",
          },
        },
        {
          type: "teamMates",
          label: "参赛成员",
          prop: "teamMates",
          slot: true,
          style: {
            textAlign: "left",
            minWidth: "120px",
          },
        },
        {
          type: "msg",
          label: "战队简介",
          prop: "msg",
          slot: true,
          style: {
            textAlign: "left",
            minWidth: "400px",
          },
        },
        {
          type: "tel",
          label: "联系方式",
          prop: "tel",
          slot: true,
          style: {
            textAlign: "center",
            minWidth: "180px",
          },
        },
        {
          type: "like",
          label: "获赞数",
          prop: "like",
          slot: true,
          style: {
            textAlign: "center",
            minWidth: "120px",
          },
        },
      ],
      selectData: [],
      editVisible: false,
      editForm: {},
      rules: {
        formId: [
          { required: true, message: "FORMID不能为空", trigger: "change" },
        ],
        eventname: [
          { required: true, message: "赛季不能为空", trigger: "change" },
        ],
        msg: [
          { required: true, message: "战队简介不能为空", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    finishChoose() {
      if (this.selectData.length === 0) {
        return this.$message.error("请选择一支队伍作为冠军！");
      }
      if (this.selectData.length !== 1) {
        this.selectData = [];
        this.$refs.multipleTable.clearSelection();
        return this.$message.error("只能选择一个战队作为冠军!");
      }
      this.id = this.selectData[0].id;
      this.searchDialog = false;
    },
    // 选择存储
    handleSelectionChange(arr) {
      this.selectData = arr;
    },
    search() {
      if (!this.team_name) {
        this.$message.error("请输入战队名称！");
      } else {
        searchId(this.team_name)
          .then((res) => {
            this.teamIdList = res.data;
          })
          .catch(() => {
            this.$message.error("服务器异常，请联系管理员");
          });
      }
    },
    // 获取赛季
    initSeason() {
      getAllEvents()
        .then((res) => {
          this.options = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //发布
    submit() {
      if (this.id === null || this.chaMsg === "") {
        this.$message.error("请输入选择冠军且输入简介！");
      } else {
        if (!this.eventname && this.eventname === "") {
          this.$message.error("请选择赛季！");
        } else {
          this.publishChamp();
        }
      }
    },
    publishChamp() {
      const data = {
        formId: this.id,
        eventname: this.eventname,
        msg: this.chaMsg,
      };
      this.loading = true;
      saveCham(data)
        .then(() => {
          this.$message.success("冠军发布成功！");
          this.initAllCham();
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },
    initAllCham() {
      getAllCham()
        .then((res) => {
          this.cham = res.data;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 删除冠军
    handleDelete(data) {
      this.$confirm("此操作将删除该冠军，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteCham(data.id);
          this.$message.success("删除成功！");
          this.initAllCham();
        })
        .catch(() => {});
    },
    // 编辑
    edit(data) {
      console.log("🐕 ~ data:", data);
      this.editVisible = true;
      this.$set(this.editForm, "formId", data.form.id);
      this.$set(this.editForm, "eventname", data.events.name);
      this.$set(this.editForm, "msg", data.msg);
    },
    // 更新
    updateCham(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await updateChampion(this.editForm);
          this.editForm = {};
          this.editVisible = false;
          this.$message.success("更新成功！");
          this.initAllCham();
        } else {
          this.$message.error("请完整填写表单内容！")
        }
      });
    },
    closeEditDialog() {
      this.editVisible = false;
      this.editForm = {};
    },
  },
  mounted() {
    this.initSeason();
    this.initAllCham();
  },
};
</script>

<style scoped lang="less">
table {
  border-collapse: collapse;
  margin-top: 20px;
  td {
    text-align: center;
  }
}
.title {
  padding: 10px;
  margin: 10px 0;
  border-left: 5px solid #409eff;
  border-bottom: 1px solid #dfdfdf;
}

.fontWeight {
  font-size: 16px;
  font-weight: bold;
}
.golden {
  font-size: 16px;
  font-weight: bold;
}
thead .el-table-column--selection .cell {
  display: none;
}
</style>
