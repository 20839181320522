<template>
    <div class="introduction__container">
        <AsgStatus></AsgStatus>
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="审批核销" name="first">
              <AsgVerifyPage></AsgVerifyPage>
            </el-tab-pane>
            <el-tab-pane disabled label="发布管理" name="second"></el-tab-pane>
            <el-tab-pane disabled label="赛事管理" name="third"></el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import AsgStatus from './components/AsgStatus.vue';
import AsgVerifyPage from './components/AsgVerifyPage.vue';
export default {
    name: 'AsgIntroduction',
    components: {
        AsgStatus,
        AsgVerifyPage
    },
    data() {
        return {
            activeName:'first'
        };
    },
    methods: {

    },
    created() {

    },
}
</script>
<style lang='less' scoped>
.introduction__container{
    margin-top: 32px;
}
</style>