<template>
  <div>
    <!-- 赛程表 -->
    <el-row>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="赛程管理" name="first">
          <transition name="run">
            <el-row v-show="operationVisible">
              <el-col :span="24">
                <el-steps :active="active" finish-status="success">
                  <el-step title="步骤 1"></el-step>
                  <el-step title="步骤 2"></el-step>
                  <el-step title="步骤 3"></el-step>
                  <el-step title="步骤 4"></el-step>
                </el-steps>
                <div class="opeBox">
                  <template v-if="this.active === 1">
                    <el-select
                      size="mini"
                      v-model="belong"
                      placeholder="请选择赛程分类"
                    >
                      <el-option
                        v-for="item in eventOptions"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      size="mini"
                      v-model="tag"
                      placeholder="请输入赛程标签"
                    >
                      <el-option
                        v-for="(item, index) in tagOptions"
                        :key="index"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                    <el-select size="small" v-model="team1_name" clearable filterable placeholder="请填写主场战队名">
                      <el-option
                        v-for="item in gameList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                    <el-select size="small" v-model="team2_name" clearable filterable placeholder="请填写客场战队名">
                      <el-option
                        v-for="item in gameList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </template>
                  <template v-else-if="this.active === 2">
                    <span>比赛开始时间：</span>
                    <el-input
                      v-model="startTime"
                      size="mini"
                      placeholder="2024-01-24T02:30:10.656Z"
                    >
                    </el-input>
                    <span
                      ><span style="color: red">严格按照这个格式输入</span
                      >:2024-01-24<span style="color: gray; font-weight: bold"
                        >T</span
                      >02:30:10<span style="color: gray; font-weight: bold"
                        >.656Z</span
                      ></span
                    >
                  </template>
                  <template v-else-if="this.active === 3">
                    <el-select
                      size="mini"
                      v-model="referee_value"
                      placeholder="请选择导播或裁判"
                    >
                      <el-option
                        v-for="item in instructor"
                        :key="item.chinaname"
                        :label="item.chinaname"
                        :value="item.chinaname"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      size="mini"
                      filterable
                      clearable
                      value-key="id"
                      v-model="commentary_value[0]"
                      placeholder="请选择解说1"
                    >
                      <el-option
                        v-for="item in commentary"
                        :key="item.chinaname"
                        :label="item.chinaname"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      size="mini"
                      filterable
                      clearable
                      value-key="id"
                      v-model="commentary_value[1]"
                      placeholder="请选择解说2"
                    >
                      <el-option
                        v-for="item in commentary"
                        :key="item.chinaname"
    
                        :label="item.chinaname"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <el-input
                      size="mini"
                      v-model="instructor_value"
                      placeholder="裁判（可不填）"
                    >
                    </el-input>
                  </template>
                  <div class="btn_wrap">
                    <el-button
                      v-if="active !== 1"
                      type="primary"
                      style="margin-top: 12px"
                      @click="retry"
                      size="mini"
                      ><i class="el-icon-caret-left"></i>上一步</el-button
                    >
                    <el-button
                      v-if="active !== 4"
                      type="primary"
                      style="margin-top: 12px"
                      @click="next"
                      size="mini"
                      >下一步<i class="el-icon-caret-right"></i
                    ></el-button>
                    <el-button
                      v-if="active === 4"
                      type="warning"
                      style="margin-top: 12px"
                      size="mini"
                      v-loading="btnloading"
                      element-loading-spinner="el-icon-loading"
                      element-loading-background="rgba(0, 0, 0, 0.8)"
                      @click.native="submit"
                      >发布<i class="el-icon-star-off"></i
                    ></el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </transition>
          <schedule-table ref="schedule" @operation="changeOperation" />
        </el-tab-pane>
        <el-tab-pane label="赛季管理" name="second">
          <SetSeason />
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
import { pushSchedule, getUserRoles } from "@/api/schedule/index";
import { getPlayerDetails } from "@/api/gameSeason/index.js"
import { getAllEvents } from "@/api/gameSeason/index";
export default {
  name: "",
  data() {
    return {
      active: 1,
      allTeam: [],
      team1_name: "",
      team2_name: "",
      belong: "",
      tag: "", //赛程标签
      gameList:[],
      startTime: "",
      btnloading: false,
      scheduleData: [],
      referee: [], //裁判
      referee_value: "",
      commentary: [], //解说1
      commentary_value: [],
      instructor: [], //导播
      instructor_value: "",
      bilibiliuri: "",
      operationVisible: false,
      // 选项卡
      activeName: "first",
      eventOptions: [],
      tagOptions: [
        { name: "总决赛" },
        { name: "季军赛" },
        { name: "半决赛" },
        { name: "四强赛" },
        { name: "八强赛" },
        { name: "十六强" },
        { name: "常规赛" },
        { name: "海选赛" },
        { name: "高校赛" },
        { name: "众创赛" },
        { name: "神将赛" },
        { name: "淘汰赛" },
        { name: "擂台赛" },
      ],
    };
  },
  components: {
    ScheduleTable: () => import("./components/ScheduleTable.vue"),
    SetSeason: () => import("./components/SetSeason.vue"),
  },
  watch:{
    belong(newValue){
      if(!newValue){
        this.gameList = [];
        return;
      }
      getPlayerDetails(newValue)
      .then(res=>{
        this.gameList = [];
        this.gameList = res.data.map(item =>({name:item.team_name,id:item.id}));
        this.gameList.unshift({name:"TBD",id:new Date().getTime()})
      })
      .catch((err)=>{
        this.$message.error(err)
      })
    }
  },
  methods: {
    changeOperation() {
      this.operationVisible = !this.operationVisible;
    },
    next() {
      if (this.active === 1 || this.active === 2) {
        this.active++;
      } else if (this.active === 3) {
        this.active++;
      } else {
        return;
      }
    },
    retry() {
      if (this.active === 1) {
        return;
      } else {
        this.active--;
      }
    },
    // 发布
    submit() {
      this.btnloading = true;
      this.setSchedule();
    },
    setSchedule() {
      const comMap = this.commentary_value.map(com => ({id:com.id,chinaname:com.chinaname}));
      const filterArr = comMap.filter(f => f.id !== 0);
      let commentary = JSON.stringify(filterArr);
      pushSchedule(
        this.team1_name,
        this.team2_name,
        this.startTime,
        this.belong,
        commentary,
        this.referee_value,
        this.bilibiliuri,
        this.tag
      )
        .then((res) => {
          this.$message.success(res.data);
          this.btnloading = false;
          this.$refs.schedule.belong = this.belong;
          this.$refs.schedule?.initSchedule(1, 10, this.belong);
          this.team1_name = "";
          this.team2_name = "";
          this.instructor_value = "";
          this.commentary_value = [];
          this.referee_value = "";
          this.tag = "";
          this.active = 1;
        })
        .catch(() => {
          this.$message.error("设置失败，请检查数据！");
          this.btnloading = false;
        });
    },
    //获取解说列表
    initGetCommentary() {
      let params = {
        opname: "Commentator",
      };
      getUserRoles(params)
        .then((res) => {
          this.commentary = [];
          this.commentary.push({ chinaname: "待定",id:0 });
          this.commentary = this.commentary.concat(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取裁判列表
    initGetReferee() {
      let params = {
        opname: "referees",
      };
      getUserRoles(params)
        .then((res) => {
          this.referee = [];
          this.referee.push({ chinaname: "待公布" });
          this.referee = this.referee.concat(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取导播列表
    initGetAnchor() {
      let params = {
        opname: "Anchor",
      };
      getUserRoles(params)
        .then((res) => {
          this.instructor = [];
          this.instructor.push({ chinaname: "待定" });
          this.instructor = this.instructor.concat(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // handleClick
    handleClick(tab) {
      this.activeName = tab.name;
    },
    // 获取赛季
    initSeason() {
      getAllEvents()
        .then((res) => {
          this.eventOptions = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  //METHOD结束
  created() {
    this.initGetCommentary();
    this.initGetAnchor();
    this.initGetReferee();
    this.initSeason();
  },
};
</script>

<style scoped lang="less">
.run-enter-active {
  animation: bounce-in 0.4s;
}
/* 离开的时候要激活的样式 */
.run-leave-active {
  animation: bounce-in 0.4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
.el-row {
  margin: 20px;
}
.el-input {
  width: 20%;
  margin: 10px;
}
.el-select {
  margin: 10px;
}
.btn_wrap {
  float: right;
}
</style>
