<template>
    <el-scrollbar class="comp__container--absolute">
        <TextTitle titleName="快捷跳转"></TextTitle>
        <el-button v-show="design" type="primary" size="small" @click="transferVisible = true">编辑（设计可见）</el-button>
        <ul class="container__flex">
            <li v-for="(item,index) in multipleSelection" :key="index" @click="routePath(item.path)">
                <img src="../../../assets/logo.png">
                <p>{{ item.name }}</p>
            </li>
        </ul>
        <el-dialog :visible.sync="transferVisible" title="配置快捷权限">
            <curTransfer  ref="curTransfer"></curTransfer>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" @click="handleSaveShort">保 存</el-button>
            </div>
        </el-dialog>
    </el-scrollbar>
</template>

<script>
import curTransfer from "@/view/homepage/cusTransfer.vue";
import TextTitle from "@/components/TextTitle.vue";
export default {
    name: 'asg-quick-jump',
    components: {
        curTransfer,
        TextTitle
    },
    props:{
        design:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            transferVisible: false,
            multipleSelection:[]
        };
    },
    created(){
        this.multipleSelection = JSON.parse(localStorage.getItem("asgshortcut")) ?? [];
    },
    methods: {
        handleSaveShort() {
            this.transferVisible = false;
            this.multipleSelection = this.$refs.curTransfer.save();
        },
        routePath(path){
            console.log(path,'path');
            this.$router.push({path});
        }
    }
}
</script>
<style lang='less' scoped>
.comp__container--absolute {
    width: 100%;
    height: 100%;
    .container__flex{
        display:flex;
        flex-wrap:wrap;
        width: 100%;
        li{
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:12px;
            &:hover p{
                color:#4090EE;
            }
            img{
                border-radius: 10px;
                border:1px solid #979797;
                width:80px;
                height:80px;
                margin:6px 0;
            }
            p{
                color:#303030;
                font-weight: 600;
                font-size: 0.8rem;
            }
        }
    }
}
</style>