<template>
  <div>
    <header>
      <svg-icon iconClass="promChart"></svg-icon><p>晋升图管理中心</p>
    </header>
    <el-container>
      <el-aside>
        <el-scrollbar style="height: 100%">
          <div class="nav-container">
            <div class="nav-line"></div>
            <div class="nav-box">
              <div class="icon-box">
                <i class="el-icon-circle-plus-outline"></i>
              </div>
              <h4>ASG所有赛季</h4>
            </div>
            <div
              class="nav-box"
              v-for="(item, index) in navList"
              :key="item.id"
            >
              <div
                class="icon-box"
                :class="storage[index].isActive ? '' : 'transparent-box'"
              >
                <i
                  class="el-icon-s-promotion active"
                  v-show="storage[index].isActive"
                ></i>
              </div>
              <p
                class="normal"
                :class="storage[index].isActive ? 'active' : ''"
                @click="changeActive(item, index)"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
        </el-scrollbar>
      </el-aside>
      <el-main>
        <div class="main-header">
          <div
            style="
              display: flex;
              justify-content: start;
              align-items: center;
              gap: 20px;
            "
          >
            <el-button size="mini" type="primary" @click="dialogVisible = true"
              >选择模板</el-button
            >
            <el-button size="mini" type="success" @click="submit"
              >发布</el-button
            >
            <el-popover
              placement="top-start"
              title="提示"
              width="200"
              trigger="hover"
              content="发布后无法及时刷新，请重刷侧边导航栏查看。"
            >
              <el-button size="mini" slot="reference" @click="handleRefresh">刷新</el-button>
            </el-popover>
            <h4>
              <i class="el-icon-s-promotion active"></i>{{ checkInfo.name }}
            </h4>
          </div>
          <div class="warning-text">
            <span style="margin-right: 12px">请发布模板刷新后再全屏查看</span>
            <i class="el-icon-full-screen" @click="fullVisible = true"></i>
          </div>
        </div>
        <div class="template" v-loading="loading">
          <p v-if="noChooseTemplate">暂未设计</p>
          <component
            ref="templateComponent"
            :key="componentName"
            :propPromChart="promChart"
            v-if="!noChooseTemplate"
            :is="componentName"
          >
          </component>
        </div>
      </el-main>
    </el-container>
    <el-dialog title="全屏查看" :visible.sync="fullVisible" fullscreen>
      <component
        ref="templateComponent"
        :key="componentName"
        :propPromChart="promChart"
        v-if="!noChooseTemplate"
        :is="componentName" 
        :readOnly="true"
      >
      </component>
      <span v-else style="color: #fff">暂未设置模板！</span>
    </el-dialog>
    <templateVue
      :dialogVisible.sync="dialogVisible"
      @changeName="changeComponent"
    ></templateVue>
  </div>
</template>

<script>
import { getEventProm, updateProm } from "@/api/gameSeason/index.js";
import templateVue from "@/view/drag/components/template.vue";
import sixteenTem from "./components/sixteenTem.vue";
import championTem from "./components/championTem.vue";
import eightTem from "./components/eightTem.vue";
export default {
  name: "dragIndex",
  components: {
    templateVue,
    sixteenTem,
    championTem,
    eightTem,
  },
  data() {
    return {
      navList: [],
      storage: [],
      checkInfo: {},
      noChooseTemplate: true,
      loading: false,
      dialogVisible: false,
      fullVisible: false,
      componentName: "",
    };
  },
  created() {
    this.getProm();
  },
  computed: {
    promChart() {
      return this.checkInfo.promChart;
    },
  },
  methods: {
    handleRefresh(){
      const loading = this.$loading({
          lock: true,
          text: "正在刷新数据中......",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.8)",
        });
      this.getProm();
      loading.close();
    },
    async getProm() {
      try {
        const { data } = await getEventProm();
        this.navList = data;
        this.storage = [];
        this.navList.forEach((value, index) => {
          const activeObj = {
            isActive: false,
          };
          if (index === 0) {
            activeObj.isActive = true;
          }
          this.storage.push(activeObj);
        });
        this.checkInfo = this.navList[0];
        this.checkIfTem();
      } catch (error) {
        console.log(error);
      }
    },
    changeActive(item, index) {
      this.noChooseTemplate = true;
      this.loading = true;
      this.storage.forEach((item) => {
        item.isActive = false;
      });
      this.storage[index].isActive = true;
      this.checkInfo = item;
      setTimeout(() => {
        this.loading = false;
      }, 800);
      if (!this.promChart) {
        this.noChooseTemplate = true;
      } else {
        this.noChooseTemplate = false;
        this.checkIfTem();
      }
    },
    changeComponent(name) {
      this.noChooseTemplate = false;
      this.componentName = name;
    },
    submit() {
      if (this.noChooseTemplate) {
        this.$message.error("请配置模板！");
      } else {
        const loading = this.$loading({
          lock: true,
          text: "正在上传晋升图数据",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.8)",
        });
        console.log("模板", this.$refs.templateComponent.promChart);
        updateProm(
          this.checkInfo.name,
          JSON.stringify(this.$refs.templateComponent.promChart)
        )
          .then(() => {
            setTimeout(() => {
              loading.close();
              this.$message.success("保存成功！");
            }, 1000);
          })
          .catch(() => {
            this.$message.error("保存失败！");
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 1000);
          });
      }
    },
    checkIfTem() {
      if (!this.promChart) {
        this.noChooseTemplate = true;
      } else {
        this.noChooseTemplate = false;
        const promObj = JSON.parse(this.promChart);
        this.componentName = promObj.componentName;
      }
    },
  },
};
</script>

<style scoped lang="less">
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding-bottom: 6px;
  border-bottom: 1px solid #e7e7e7;
}
.template {
  background-color: #303030;
  width: 100%;
  height: 90%;
  overflow-y: auto;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #efefef;
  }
}
header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height:40px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 12px;
  border: 1px solid #e7e7e7;
}
.el-aside,
.el-main {
  height: calc(80vh - 40px);
  border: 1px solid #e7e7e7;
  margin: 6px;
}
.nav-container {
  padding: 12px;
  height: 100%;
  width: calc(100% - 24px);
  position: relative;
  .nav-line {
    height: 90%;
    width: 0;
    position: absolute;
    top: 5%;
    left: 19px;
    border: 2px solid gray;
  }
}
.transparent-box {
  background-color: transparent !important;
}
.nav-box {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 12px 0;
  height: 20px;
  .icon-box {
    width: 30px;
    height: 16px;
    line-height: 16px;
    background-color: #fff;
    z-index: 999;
  }
}
.normal {
  cursor: pointer;
}
.normal.active {
  color: #147aff;
}
.active {
  color: #147aff;
}
.warning-text {
  color: #147aff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
/deep/.el-dialog__body {
  background-color: #303030;
}
</style>
