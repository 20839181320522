<template>
  <div>
    <!-- <TextTitle titleName="个人信息"></TextTitle>
    <el-row>
      <el-col :span="11">
        该用户的职位：<span class="fontWeight">
          {{ officium | filterRole }}</span>
      </el-col>
      <el-col :span="4">
        <el-button size="small" type="primary" @click="transferVisible = true"><i
            class="el-icon-s-tools"></i>配置快捷权限</el-button>
      </el-col>
      <el-col :span="4">
        <el-button size="small" type="primary" @click="drawer = true"><i
            class="el-icon-user-solid"></i>更新个人信息</el-button>
      </el-col>
    </el-row>
    <TextTitle titleName="我的背包"></TextTitle>
    <div v-if="bags.length > 0" class="my__bags">
      <li class="grid-item" v-for="(item, index) in bags" :key="index">
        <img src="../../assets/logo.png" />
        <el-badge 
          :value="item.isVerification ? '已核销' : '待核销'"
          class="item"
          :type="item.isVerification ? 'success' : 'warning'"
        >
          <p>{{ item.name }}</p>
        </el-badge>
      </li>
    </div>
    <el-empty description="背包空空如也" v-else></el-empty>
    <el-drawer title="更新信息" :visible.sync="drawer" direction="rtl">
      <div class="drawer-body">
        <el-row>
          <el-upload action :auto-upload="false" list-type="picture-card" :show-file-list="false" style="margin: 10px"
            :before-upload="beforeAvatarUpload" :on-change="transformBase" :on-success="uploadImg">
            上传头像
          </el-upload>
        </el-row>
        <el-row type="flex" style="gap: 24px">
          <el-input v-model="ChinaName" placeholder="请输入中文名" maxlength="10" size="mini" show-word-limit></el-input>
          <el-button @click="updateName" type="primary" size="mini"><i class="el-icon-edit"></i>修改</el-button>
        </el-row>
      </div>
    </el-drawer>-->
    <HomeDesign :setting-form="settingForm" :design="false"></HomeDesign>
  </div>
</template>

<script>
import { updateChinaName, enrollAdmin, uploadImg, getInfo, getMyShopping } from "@/api/home";

import HomeDesign from "../customWorker/components/HomeDesign.vue";
export default {
  components: {
    HomeDesign
  },
  data() {
    return {
      transferVisible: false,
      multipleSelection: [],
      ChinaName: "",
      userName: "",
      password: "",
      chinaname: "",
      eMail: "",
      drawer: false,
      adminPassword: "",
      logoImg: "",
      //搜索
      isOperation: false, //是否是操作栏
      // 后续更新的
      settingForm:{}
    };
  },
  created() {
    // this.multipleSelection = JSON.parse(localStorage.getItem("asgshortcut")) ?? [];
    // this.initMyBag();
    try {
      if(!window.localStorage.getItem('customWorker-asg')) return;
      const json = JSON.parse(window.localStorage.getItem('customWorker-asg'));
      this.settingForm = json.settingForm || {};
    } catch (error) {
      this.$message.error(error.message);
    }
  },

  methods: {
    initMyBag() {
      getMyShopping().then(res => {
        this.bags = res.data;
      })
    },
    updateName() {
      updateChinaName(this.ChinaName)
        .then(() => {
          this.$message.success("修改成功！");
          this.initGetInfo();
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    initGetInfo() {
      getInfo()
        .then((res) => {
          this.$store.commit("getUserInfo", res.data);
          sessionStorage.setItem("money", res.data.money);
          sessionStorage.setItem("baseImg", res.data.base64);
          sessionStorage.setItem("chinaname", res.data.chinaname);
          sessionStorage.setItem("officium", res.data.officium);
          sessionStorage.setItem("id", res.data.id);
          sessionStorage.setItem("isadmin", res.data.isadmin);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    beAdmin() {
      enrollAdmin(this.userName, this.password, this.ChinaName, this.eMail)
        .then(() => {
          this.$message.success("成功！");
          this.initGetUsers();
        })
        .catch((err) => {
          this.$message.error("失败" + err.message);
        });
    },
    //上传头像
    transformBase(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      const self = this;
      reader.onload = function () {
        // 转换完成输出该文件base64编码
        self.logoImg = reader.result;
        self.$store.commit("SET_LOGO", self.logoImg);
        self.uploadImg(self.logoImg);
      };
    },
    //上传请求
    uploadImg(logoImg) {
      uploadImg(logoImg)
        .then(() => {
          this.$message.success("更新成功！")
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //判断文件大小
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      console.log(isLt2M);
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    handleSaveShort() {
      this.transferVisible = false;
      this.multipleSelection = this.$refs.curTransfer.save();
    },
    handleClick(item) {
      switch (item.type) {
        case "1":
          this.$router.push("/index/schedule");
          break;
        case "2":
          this.$router.push("/index/news");
          break;
        case "3":
          this.$router.push("/index/blok");
          break;
        case "4":
          this.$router.push("/index/banpick");
          break;
        case "5":
          this.$router.push("/index/gamepic");
          break;
        case "6":
          this.$router.push("/index/ballot");
          break;
        default:
          this.$router.push("/");
      }
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    name() {
      return this.userInfo.chinaname || sessionStorage?.getItem("chinaname");
    },
    money() {
      return this.userInfo.money || sessionStorage?.getItem("money") || 0;
    },
    userId() {
      return this.userInfo.id || sessionStorage?.getItem("id");
    },
    officium() {
      return this.userInfo.officium || sessionStorage?.getItem("officium");
    },
    imgUrl() {
      return (
        this.$store.state.userInfo.base64 || sessionStorage?.getItem("baseImg")
      );
    },
  },
};
</script>

<style scoped lang="less">
@btn-color: #53a0d4;

.updateInfo {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  overflow-y: scroll;
  max-height: 200px;
  width: 40%;
}

.el-card {
  margin: 0 20px;
  height: auto;
}

.el-select {
  width: 70%;
  margin-left: 10px;
  margin-bottom: 10px;
}

.el-image {
  margin-right: 10%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

/deep/.image-slot {
  color: #fff;
  background: red;
  text-align: center;
  line-height: 120px;
  font-size: 25px;
}

.userinfo {
  .name {
    width: 150px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 2em;
    margin-bottom: 10px;
  }
}

.login-info {
  p {
    line-height: 24px;
    font-size: 14px;
    color: #999;

    span {
      color: #666;
      margin-left: 60px;
    }
  }
}

.min-width-list {
  width: 300px;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.box-card {
  .operation-btn {
    margin: 14px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .el-button {
      width: 60px;
    }
  }

  border: 1px solid #e7e7e7;
  padding: 20px;
  overflow: auto;
  height: 240px;
}

.dialog-footer {
  text-align: center;
}

.drawer-body {
  padding: 12px;
}

.menu-item {
  display: flex;
  cursor: pointer;
  justify-content: space-around;
  align-items: center;

  .menu-box {
    width: 60px;
    height: 60px;

    img {
      box-sizing: border-box;
      padding: 5px;
      border-radius: 10px;
      background-color: #eef7fe;
      display: block;
      width: 60px;
      height: 60px;
    }
  }
}

.el-col {
  margin: 12px;
}

.fontWeight {
  font-weight: bold;
}

//我的背包
.my__bags {
  display: grid;
  grid-template-columns: repeat(4fr);
  margin: 24px;

  .grid-item {
    img{
      width: 80px;
      height: 80px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    border: 1px solid #e7e7e7;
    cursor:pointer;
    &:hover{
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.42);
    }
    p{
      font-size: 0.9rem;
      font-weight: bold;
    }
  }
}
</style>
