<template>
    <div>
        <div class="empty-consult" v-if="showEmpty && !design">
            <el-result icon="warning" title="未设计首页提示" subTitle="尊敬的用户，您尚未自定义首页，请前往个性化工作台设置。">
                <template slot="extra" >
                    <el-button type="primary" size="medium" @click="$router.push({path:'/myCustomWorker'})">点我前往</el-button>
                </template>
            </el-result>
        </div>
        <div v-else class="main__container"
            :style="`grid-template-columns':'repeat(${settingForm.columns},1fr)`">
            <li 
                 v-for="(item, index) in componentList" 
                :style="{ 
                    'background': !!item.component ? '#fff' : '#acd3fa',
                    'height':(item.height ? item.height : settingForm.height) + 'px',
                    'flex-direction':design ? 'column' : 'row'
                }"
                class="box-li"
                data-drop="copy"
                :data-index="index"
                :key="index"
            >
                <el-input-number @change="heightChange" v-show="design && item.component" size="small" v-model="item.height" :min="250" :max="500" :step="50" label="请输入高度"></el-input-number>
                <div v-show="!!item.component && design" class="delete__icon" @click="deleteItem(index)">
                    <i class="el-icon-delete-solid"></i>
                </div>
                <i v-show="!item.component" class="el-icon-minus trem" @click="deletePort(index)"></i>
                <component :is="item.component" :design="design"></component>
            </li>
            <div v-if="design" class="addList__icon" @click="addPort">
                <i class="el-icon-plus"></i>
            </div>
        </div>
    </div>
</template>

<script>
import asgMyCollaction from './asg-my-collaction.vue';
import asgUserInfo from './asg-user-Info.vue';
import asgQuickJump from './asg-quick-jump.vue';
import asgMyShopping from './asg-my-shopping.vue';
import asgMyGuess from './asg-my-guess.vue';
import asgOtherSystem from './asg-other-system.vue';
export default {
    name: 'HomeDesign',
    components: {
        asgMyCollaction,
        asgUserInfo,
        asgQuickJump,
        asgMyShopping,
        asgMyGuess,
        asgOtherSystem
    },
    props: {
        settingForm: {
            type: Object,
            default: () => { },
        },
        changeCount: {
            type: Number,
            default: 0
        },
        design: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            componentList: [],
            showEmpty: false,
            customSetting: {}
        };
    },
    watch: {
        changeCount() {
            this.setColumns();
        }
    },
    created() {
        try {
            console.log('进来了吗');
            if (!window.localStorage.getItem('customWorker-asg') || Object.keys(this.settingForm).length === 0) {
                this.showEmpty = true;
                return;
            }
            const json = JSON.parse(window.localStorage.getItem('customWorker-asg'));
            console.log(json, 'son.componentList ');
            if (!!json.componentList && json.componentList.length > 0) {
                this.componentList = json.componentList.filter(item => item !== null);
            }
        } catch (error) {
            console.log(error.message);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.setColumns();
        })
    },
    methods: {
        heightChange(){
            this.$forceUpdate();
        },
        deletePort(index) {
            this.componentList.splice(index, 1);
        },
        addPort() {
            this.componentList.push({});
        },
        deleteItem(index) {
            this.componentList[index] = {};
            this.$forceUpdate();
        },
        setComponent(index, type) {
            console.log(index, type);
            this.componentList[index].component = type;
            this.$forceUpdate();
        },
        setColumns() {
            const dom = document.querySelector('.main__container');
            dom.style.gridTemplateColumns = `repeat(${this.settingForm.column},1fr)`;
            const liList = document.querySelectorAll('.box-li');
            const borderHeight = (this.settingForm.height - this.settingForm.padding * 2) + 'px';
            if (this.design) {
                const addIcon = document.querySelector('.addList__icon');
                addIcon.style.height = borderHeight;
            }
            liList.forEach(li => {
                li.style.padding = this.settingForm.padding + 'px';
            })
        },
    },
}
</script>
<style lang='less' scoped>
.main__container {
    background-color: #ffffff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    padding: 24px;
    gap: 24px;

    li {
        background-color: #acd3fa;
        display: flex;
        height: 220px;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        border-radius: 10px;
        .delete__icon {
            position: absolute;
            top: -15px;
            right: -15px;
            width: 40px;
            height: 40px;
            background: #f40;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                background: lighten(#f40, 20%);
            }

            i {
                font-size: 24px;
                color: #fff;
            }
        }

        &.drop-over {
            background: #303030 !important;
        }

        .tag {
            background: #fff;
            height: 50px;
            width: 100px;
        }
    }

    .addList__icon {
        cursor: pointer;
        width: 100%;
        border: 2px dashed #969696;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        &:hover {
            background: lighten(#409EFF, 20%);

            i {
                color: #409EFF;
            }
        }

        i {
            font-size: 3rem;
            color: #969696;
        }
    }
}

.trem {
    color: #969696;
    font-size: 3rem !important;
    cursor: pointer;
}
.empty-consult{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>