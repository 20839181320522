<template>
  <div>
    <el-tabs v-model="activeName">
    <el-tab-pane label="任务下发" name="1">
       <management></management>
    </el-tab-pane>
    <el-tab-pane label="解说人员" name="2">
      <commentator></commentator>
    </el-tab-pane>
    <!-- <el-tab-pane label="裁判人员" name="3">
      <referee></referee>
    </el-tab-pane> -->
    <el-tab-pane label="官网用户" name="4">
      <suvivors v-if="activeName === '4'"></suvivors>
    </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
export default {
   components:{
    commentator:()=>import('./components/commentator.vue'),
    referee:()=>import('./components/referee.vue'),
    management:()=>import('./components/management.vue'),
    suvivors:()=>import('./components/suvivors.vue')
   },
   data(){
    return{
      activeName:'1'
    }
   }
}
</script>

<style scoped lang="less">

</style>
