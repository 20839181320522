<template>
  <el-container>
    <el-aside width="auto">
      <common-aside />
    </el-aside>
    <el-container>
      <el-header><common-header /></el-header>
      <el-scrollbar style="height: calc(100vh - 60px)">
        <el-main style="min-height:calc(100vh - 60px - 70px)">
          <el-breadcrumb style="margin-bottom:12px" v-if="$route.path !== '/index'" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="item in $route.matched" :to="{ path: '/index' }">
              {{ item.name}}
            </el-breadcrumb-item>
          </el-breadcrumb>
          <transition name="fade-transform" mode="out-in">
            <router-view></router-view>
          </transition>
        </el-main>
        <el-footer>
          <p>© 2023 - 2024 Avengers Gaming 版权所有</p>
          <p ><a style="color:#111" href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2024037646号-1</a></p>
        </el-footer>
      </el-scrollbar>
    </el-container>

    <projOperation v-if="$route.path !== '/index'"></projOperation>
  </el-container>
</template>

<script>
import CommonAside from "@/components/CommonAside.vue";
import CommonHeader from "@/components/CommonHeader.vue";
import projOperation from "@/components/projOperation.vue";
export default {
  name: "HomePage",
  components: { CommonAside, CommonHeader, projOperation },
  data() {
    return {
      isCollapse: false,
    };
  },
};
</script>

<style scoped lang="less">
.el-header {
  padding: 0;
}
.el-aside::-webkit-scrollbar {
  display: none;
}

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-20px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
/deep/.el-footer {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 13px;
    color: #303030;
  }
}
.el-main{
  overflow-x:hidden;
}
</style>
