class Menu {
    constructor(){
      this.menuList = {}
    }
    showMenu(menuList,$event){
        const oldDiv = document.getElementById('menu');
        if(oldDiv){
            oldDiv.parentNode.removeChild(oldDiv);
        }
        this.menuList = menuList;
        const div = document.createElement('div');
        div.style.width = '200px';
        div.style.height = '200px';
        div.style.position = 'fixed';
        div.style.left = `${$event.clientX}px`;
        div.style.top = `${$event.clientY}px`;
        div.style.background = '#999';
        div.id = 'menu';
        div.innerHTML = '我超！！'
        document.body.appendChild(div);
      }
}

const menu = new Menu();
export default menu;