export const transferData = [
    {
        id:'1',
        name:'赛程安排',
        type:'1',
        value:'3',
        path:'/index/schedule',
    },
    {
        id:'2',
        name:'公告发布',
        type:'2',
        value:'10',
        path:'/index/news'
    },
    {
        id:'3',
        name:'冠军发布',
        type:'3',
        value:'6',
        path:'/index/blok'
    },
    {
        id:'4',
        name:'合作伙伴',
        type:'4',
        value:'8',
        path:'/index/banpick'
    },
    {
        id:'5',
        name:'对战图制作',
        type:'5',
        value:'hot',
        path:'/index/gamepic'
    },
    {
        id:'6',
        name:'抽签管理',
        type:'6',
        value:'1',
        path:'/index/ballot'
    },
    {
        id:'7',
        name:'商品核销',
        type:'7',
        value:'1',
        path:'/index/storeVerify'
    },
    {
        id:'8',
        name:'商品管理',
        type:'8',
        value:'2',
        path:'/index/storeManager'
    },
]