<template>
     <el-scrollbar class="comp__container--absolute">
        <TextTitle title-name="ASG其他系统跳转"></TextTitle>
        <li>ASG解说端 <el-link type="primary" href="https://commentary.idvasg.cn">https://commentary.idvasg.cn</el-link></li>
        <li>ASG官网 <el-link type="primary" href="https://idvasg.cn">https://idvasg.cn</el-link></li>
        <li> <el-link type="primary" disabled>第五人格白嫖JOKER链接</el-link></li>
     </el-scrollbar>
</template>

<script>
import TextTitle from '@/components/TextTitle.vue';
export default {
  name:'ASGOtherSystem',
   components:{
    TextTitle
   },
  methods: {

  },
  created() {

  },
}
</script>
<style lang='less' scoped>
.comp__container--absolute {
    width: 100%;
    height: 100%;
    li{
        margin:16px 0;
    }
}
</style>