import { render, staticRenderFns } from "./asg-other-system.vue?vue&type=template&id=de6b3450&scoped=true"
import script from "./asg-other-system.vue?vue&type=script&lang=js"
export * from "./asg-other-system.vue?vue&type=script&lang=js"
import style0 from "./asg-other-system.vue?vue&type=style&index=0&id=de6b3450&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de6b3450",
  null
  
)

export default component.exports